import './DistributedCompute.scss';
import React from 'react';
import links from '@constants/links';
import Title from '../components/Title';
import Card from '../components/Card';
import Buttons from '../components/Card/components/Buttons';
import GithubIcon from '../../assets/github.svg';
import SparkIcon from './assets/spark.svg';
import TrinoIcon from './assets/trino.svg';
import PrestoIcon from './assets/presto.svg';
import DaskIcon from './assets/dask.svg';

const DistributedCompute: React.FC = () => {
  return (
    <div className="TDB-OpenSourcePage__DistributedCompute">
      <Title>Distributed Compute</Title>
      <Card
        gradientColor="#E25A1C"
        icon={SparkIcon}
        title="TileDB-Spark"
        description="The TileDB datasource driver for Spark. Supports TileDB-VCF datasets and Spark versions 3.2.0 and 2.4.4."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbSpark }]} />
      </Card>
      <Card
        gradientColor="#FF0094"
        icon={TrinoIcon}
        title="TileDB-Trino"
        description="Connector for running SQL on TileDB arrays via Trino."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbTrino }]} />
      </Card>
      <Card
        gradientColor="#08E7E7"
        icon={PrestoIcon}
        title="TileDB-Presto"
        description="Connector for running SQL on TileDB arrays via Presto."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbPresto }]} />
      </Card>
      <Card
        gradientColor="rgba(255, 2, 15, 0.5)"
        icon={DaskIcon}
        title="In the community"
        description="TileDB is natively supported as a storage type in Dask, enabling transparent IO, including chunked slicing of a TileDB array as a dask.array object."
        className="TDB-OpenSourcePage__DistributedCompute__in-the-community"
      />
    </div>
  );
};

export default DistributedCompute;
