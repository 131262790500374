import React from 'react';
import DistributedCompute from '@page-components/open-source/SubPages/DistributedCompute';
import OpenSourcePageWrapper from '@page-components/open-source/OpenSourcePageWrapper';

const OpenSourcePage = () => {
  return (
    <OpenSourcePageWrapper>
      <DistributedCompute />
    </OpenSourcePageWrapper>
  );
};

export default OpenSourcePage;
